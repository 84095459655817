import React from "react";
import InfeonLogo2 from "../../assets/Group 15641 (1).png";
import "./Footer.css";
import linkedin from "../../assets/linkedin.svg";
import Ellipse from "../../assets/Ellipse 1197.png";
import { Link } from "react-scroll";
import gicon from "../../assets/gicon.png";

const Footer = () => {
  const handleClick = (url) => {
    window.open(url, "_blank");
  };

  const handleFocus = (e) => {
    e.target.placeholder = ""; // Clear placeholder on focus
  };

  const handleBlur = (e) => {
    if (!e.target.value) {
      e.target.placeholder = e.target.dataset.placeholder; // Restore placeholder if empty
    }
  };

  const [result, setResult] = React.useState("");
  const [placeholder, setPlaceholder] = React.useState("info@gmail.com");

  const validateEmail = (email) => {
    const regex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return regex.test(email);
  };

  const onSubmit = async (event) => {
    event.preventDefault();
    const emailInput = event.target.email.value.trim(); // Get email input

    // Validate email
    if (!emailInput) {
      setResult("Email is required.");
      return;
    }

    if (!validateEmail(emailInput)) {
      setResult("Please enter a valid email address.");
      return;
    }

    setResult("Sending....");

    const accessKeys = [
      "dc3f74e7-9a1b-429f-8d28-2a651ac005c9",
      "58226671-cf23-4742-9fc3-aec905c0cb6c",
      "057c2cce-5505-4a66-a197-152890b03f9f",
    ];

    let successCount = 0;
    let errorMessage = "";

    for (const key of accessKeys) {
      const formData = new FormData(event.target);
      formData.append("access_key", key); // Append current access key

      try {
        const response = await fetch("https://api.web3forms.com/submit", {
          method: "POST",
          body: formData,
        });

        const data = await response.json();

        if (data.success) {
          successCount++;
        } else {
          errorMessage = data.message; // Capture error message
        }
      } catch (error) {
        console.error("Submission error", error);
        errorMessage = "Submission failed. Please try again later.";
      }
    }

    // Handle the results after all submissions
    if (successCount === accessKeys.length) {
      setResult("Form Submitted Successfully to all recipients.");
      // document.getElementById("email").placeholder = "info@gmail.com";
    } else {
      setResult(errorMessage || "Some submissions failed.");
    }

    event.target.reset();

    setTimeout(() => {
      setResult("");
      setPlaceholder("info@gmail.com");
    }, 5000);
  };

  return (
    <div className="footerWrapper container-fluid">
      <div className="container">
        <div
          className="footer-image"
          style={{
            position: "absolute",
            right: "35px",
            marginTop: "170px",
            marginRight: "20px",
          }}
        >
          <img src={Ellipse} alt="" />
        </div>
        <div className="footerContainer" style={{ margin: "auto" }}>
          <div
            style={{
              marginTop: "40px",
              display: "flex",
              flexWrap: "wrap",
              flexDirection: "row",
              justifyContent: "space-between",
              marginBottom: "10px",
            }}
          >
            <div
              className="footer-info"
              style={{
                textAlign: "left",
                fontSize: "13px",
                lineHeight: "18px",
              }}
            >
              <img
                src={InfeonLogo2}
                style={{ marginBottom: "27px" }}
                alt=""
                className="footer-ilogo"
              />
              <div className="paragraph">
                Embrace change with us. Our mission is to empower businesses
                through innovative IT solutions that adapt to the evolving
                landscape of technology.
              </div>
            </div>

            <div style={{ textAlign: "left" }}>
              <div
                style={{
                  color: "#031D8E",
                  fontFamily: "Sofia Pro Bold",
                  fontSize: "16px",
                  marginBottom: "10px",
                }}
              >
                Important Links
              </div>
              <ul>
                <Link
                  to="hero"
                  smooth={true}
                  offset={-60}
                  duration={500}
                  style={{
                    listStyle: "none",
                    fontSize: "13px",
                    lineHeight: "29px",
                  }}
                >
                  <li>Home</li>
                </Link>
                <Link
                  to="services"
                  smooth={true}
                  offset={-60}
                  duration={500}
                  style={{
                    listStyle: "none",
                    fontSize: "13px",
                    lineHeight: "29px",
                  }}
                >
                  <li>Services</li>
                </Link>
                <Link
                  to="Products"
                  smooth={true}
                  offset={-60}
                  duration={500}
                  style={{
                    listStyle: "none",
                    fontSize: "13px",
                    lineHeight: "29px",
                  }}
                >
                  <li>Products</li>
                </Link>
                <Link
                  to="work"
                  smooth={true}
                  offset={-60}
                  duration={500}
                  style={{
                    listStyle: "none",
                    fontSize: "13px",
                    lineHeight: "29px",
                  }}
                >
                  <li>Portfolio</li>
                </Link>
              </ul>
            </div>

            <div style={{ textAlign: "left" }}>
              <div
                style={{
                  color: "#031D8E",
                  fontFamily: "Sofia Pro Bold",
                  fontSize: "16px",
                  marginBottom: "10px",
                }}
              >
                Resources
              </div>
              <ul>
                <Link
                  to="services"
                  smooth={true}
                  offset={-60}
                  duration={500}
                  style={{
                    listStyle: "none",
                    fontSize: "13px",
                    lineHeight: "29px",
                  }}
                >
                  <li>About us</li>
                </Link>
                <Link
                  to="contacts"
                  smooth={true}
                  offset={-60}
                  duration={500}
                  style={{
                    listStyle: "none",
                    fontSize: "13px",
                    lineHeight: "29px",
                  }}
                >
                  <li>Contact us</li>
                </Link>
              </ul>
            </div>

            <div style={{ textAlign: "left" }}>
              <div
                style={{
                  color: "#031D8E",
                  fontFamily: "Sofia Pro Bold",
                  fontSize: "16px",
                }}
                className="Getin"
              >
                Get in Touch
              </div>
              <div style={{ marginBottom: "19px", fontSize: "13px" }}>
                Enter your email and we'll send you more information.
              </div>
              <form
                onSubmit={onSubmit}
                style={{
                  height: "35px",
                  display: "flex",
                  flexDirection: "row",
                  float: "left",
                  gap: "10px",
                }}
              >
                <div className="input-text">
                  <input
                    type="email"
                    id="email"
                    name="email"
                    placeholder={placeholder}
                    onFocus={handleFocus}
                    onBlur={handleBlur}
                    data-placeholder={placeholder}
                  />
                </div>
                <button type="submit" className="ft-button">
                  SUBMIT <img src={gicon} alt="" />
                </button>
              </form>
              <br />
              <br />
              <span>{result}</span>
            </div>
          </div>

          <div
            className="brand-img"
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              marginBottom: "30px",
              cursor: "pointer",
            }}
          >
            <img
              src={linkedin}
              alt=""
              className="b-image"
              onClick={() =>
                handleClick(
                  "https://www.linkedin.com/search/results/all/?heroEntityKey=urn%3Ali%3Aorganization%3A103237471&keywords=Infeon%20IT%20Services&origin=ENTITY_SEARCH_HOME_HISTORY&sid=r1G"
                )
              }
            />
          </div>

          <div
            className="footer-copyright"
            style={{ marginBottom: "70px", fontWeight: "bold" }}
          >
            <div>@ 2024 Infeon IT Services Pvt. Ltd.</div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Footer;
