import React, { useEffect, useState } from "react";
import Marquee from "react-fast-marquee";
import AOS from "aos";
import "./Contact.css";
import itgenic from "../../assets/itechLogo.png";
import encred from "../../assets/encred-logo.png";
import exactspace from "../../assets/exactspace-logo.png";
import ententia from "../../assets/extentia-logo.webp";
import hapido from "../../assets/Hapido logo.svg";
import healthi from "../../assets/healthi-logo.png";
import itspark from "../../assets/itspark-logo.png";
import namah from "../../assets/namah-logo.png";
import supersourcing from "../../assets/supersourcing.svg";
import takespeks from "../../assets/techspakes-logo.png";
import trustin from "../../assets/trustin logo.svg";
import uex from "../../assets/uex logo.svg";
import gicon from "../../assets/gicon.png";

const Contact = () => {
  useEffect(() => {
    AOS.init({ duration: 1000 });
  }, []);

  const [formData, setFormData] = useState({
    name: "",
    email: "",
    mobile: "",
    lookingFor: "",
    message: "",
  });

  const [formErrors, setFormErrors] = useState({
    name: "",
    email: "",
    mobile: "",
    lookingFor: "",
    message: "",
  });

  const [result, setResult] = useState("");

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleFocus = (e) => {
    e.target.placeholder = ""; // Clear placeholder on focus
  };

  const handleBlur = (e) => {
    if (!e.target.value) {
      e.target.placeholder = e.target.dataset.placeholder; // Restore placeholder if empty
    }
  };

  const accessKeys = {
    serviceA: {
      key: "dc3f74e7-9a1b-429f-8d28-2a651ac005c9",
    },
    serviceB: {
      key: "58226671-cf23-4742-9fc3-aec905c0cb6c",
    },
    serviceC: {
      key: "057c2cce-5505-4a66-a197-152890b03f9f",
    },
  };

  const validateForm = () => {
    let errors = {};
    const namePattern = /^[A-Za-z ]+$/; // Allow letters and spaces
    const mobilePattern = /^\d{10}$/; // Example: 10-digit mobile number

    if (!formData.name) {
      errors.name = "Name is required";
    } else if (!namePattern.test(formData.name)) {
      errors.name = "Name can only contain letters and spaces";
    }
    if (!formData.email) {
      errors.email = "Email is required";
    } else if (!/\S+@\S+\.\S+/.test(formData.email)) {
      errors.email = "Email address is invalid";
    }
    if (!formData.mobile) {
      errors.mobile = "Mobile number is required";
    } else if (!mobilePattern.test(formData.mobile)) {
      errors.mobile = "Mobile number must be 10 digits";
    }
    if (!formData.lookingFor) {
      errors.lookingFor = "Looking for is required";
    }
    if (!formData.message) {
      errors.message = "Message is required";
    }

    setFormErrors(errors);
    return Object.keys(errors).length === 0; // Return true if no errors
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    // Validate form data
    if (!validateForm()) {
      setResult("Please correct the errors in the form.");
      return;
    }

    setResult("Sending...");

    // Prepare to send requests to all services
    const results = await Promise.all(
      Object.keys(accessKeys).map(async (service) => {
        const { key: accessKey } = accessKeys[service];

        const formDataToSend = new FormData();
        formDataToSend.append("access_key", accessKey);
        formDataToSend.append("name", formData.name);
        formDataToSend.append("email", formData.email);
        formDataToSend.append("mobile", formData.mobile);
        formDataToSend.append("lookingFor", formData.lookingFor);
        formDataToSend.append("message", formData.message);

        try {
          const response = await fetch("https://api.web3forms.com/submit", {
            method: "POST",
            body: formDataToSend,
          });
          return response.json();
        } catch (error) {
          console.error("Error sending to service:", service, error);
          return { success: false, message: `Failed to send to ${service}` };
        }
      })
    );

    // Process results to determine overall success
    const allSuccess = results.every((data) => data.success);
    if (allSuccess) {
      setResult("Thank you for contacting us!");
      e.target.reset(); // Reset the form fields
      setFormData({
        name: "",
        email: "",
        mobile: "",
        lookingFor: "",
        message: "",
      });
      document.getElementById("name").placeholder = "Name";
      document.getElementById("email").placeholder = "info@gmail.com";
      document.getElementById("mobile").placeholder = "Phone No";
      document.getElementById("lookingFor").placeholder =
        "CustomApp Development";
      document.getElementById("message").placeholder = "Type your message";
    } else {
      setResult("Some emails failed to send. Please check and try again.");
    }
  };

  return (
    <section id="contacts">
      <div className="container-fluid contacts ">
        <div className=" container">
          <div className="headlineP7">Do you have any idea in your mind?</div>
          <div className="page7content">
            <div className="form-container">
              <form onSubmit={handleSubmit} className="form">
                <div className="form-row">
                  <div className="form-group" style={{ float: "left" }}>
                    <label htmlFor="name">Name</label>
                    <input
                      type="text"
                      id="name"
                      name="name"
                      placeholder="Name"
                      value={formData.name}
                      onChange={handleChange}
                      onFocus={handleFocus}
                      onBlur={handleBlur}
                      data-placeholder="Name"
                      className={formErrors.name ? "error" : ""}
                    />
                    {formErrors.name && (
                      <span className="error-text">{formErrors.name}</span>
                    )}
                  </div>
                  <div className="form-group" style={{ float: "left" }}>
                    <label htmlFor="email">Email</label>
                    <input
                      type="email"
                      id="email"
                      name="email"
                      placeholder="info@gmail.com"
                      value={formData.email}
                      onChange={handleChange}
                      onFocus={handleFocus}
                      onBlur={handleBlur}
                      data-placeholder="info@gmail.com"
                      className={formErrors.email ? "error" : ""}
                    />
                    {formErrors.email && (
                      <span className="error-text">{formErrors.email}</span>
                    )}
                  </div>
                </div>
                <div className="form-row">
                  <div
                    className="form-group"
                    style={{ clear: "both", float: "left" }}
                  >
                    <label htmlFor="mobile">Mobile Number</label>
                    <input
                      type="text"
                      id="mobile"
                      name="mobile"
                      placeholder="Phone No"
                      value={formData.mobile}
                      onChange={handleChange}
                      onFocus={handleFocus}
                      onBlur={handleBlur}
                      data-placeholder="Phone No"
                      className={formErrors.mobile ? "error" : ""}
                    />
                    {formErrors.mobile && (
                      <span className="error-text">{formErrors.mobile}</span>
                    )}
                  </div>
                  <div className="form-group" style={{ float: "left" }}>
                    <label htmlFor="lookingFor">Looking For</label>
                    <input
                      type="text"
                      id="lookingFor"
                      name="lookingFor"
                      placeholder="CustomApp Development"
                      value={formData.lookingFor}
                      onChange={handleChange}
                      onFocus={handleFocus}
                      onBlur={handleBlur}
                      data-placeholder="CustomApp Development"
                      className={formErrors.lookingFor ? "error" : ""}
                    />
                    {formErrors.lookingFor && (
                      <span className="error-text">
                        {formErrors.lookingFor}
                      </span>
                    )}
                  </div>
                </div>
                <div className="form-group-message" style={{ clear: "both" }}>
                  <label htmlFor="message" style={{ marginLeft: "5px" }}>
                    Message
                  </label>
                  <textarea
                    id="message"
                    name="message"
                    rows={6}
                    value={formData.message}
                    onChange={handleChange}
                    onFocus={handleFocus}
                    onBlur={handleBlur}
                    data-placeholder="Type your message"
                    className={formErrors.message ? "error" : ""}
                  />
                  {formErrors.message && (
                    <span className="error-text">{formErrors.message}</span>
                  )}
                </div>
                <button type="submit" className="contact-button">
                  SUBMIT
                  <img src={gicon} alt="" />
                </button>
                <br />
                <br />
                <span>{result}</span>
              </form>
            </div>
            <div className="clientAd">
              <div
                style={{ lineHeight: 0.9, position: "absolute", top: "40px" }}
                data-aos="fade-up"
              >
                <div className="clientTitle">Clients we cater</div>
                <div className="horizontalLine7"> </div>
                <div className="client-subtitle">
                  Join hands with us & take off your business
                </div>
              </div>
              <div className="clientLogos7">
                <div
                  className="clientLogoSection"
                  data-aos="flip-up"
                  data-aos-anchor-placement="center-bottom"
                >
                  <Marquee delay={2} speed={40}>
                    <div className="logos-slide-contact">
                      <img src={itgenic} alt="" />
                      <img src={encred} alt="" />
                      <img src={exactspace} alt="" />
                      <img src={ententia} alt="" />
                      <img src={hapido} alt="" />
                      <img src={healthi} alt="" />
                      <img src={itspark} alt="" />
                      <img src={namah} alt="" />
                      <img src={supersourcing} alt="" />
                      <img src={takespeks} alt="" />
                      <img src={trustin} alt="" />
                      <img src={uex} alt="" />
                    </div>
                  </Marquee>
                </div>
                <div
                  className="clientLogoSection2"
                  data-aos="flip-down"
                  data-aos-anchor-placement="center-bottom"
                >
                  <Marquee delay={2} speed={40} direction="right">
                    <div className="logos-slide-contact">
                      <img src={itgenic} alt="" />
                      <img src={encred} alt="" />
                      <img src={exactspace} alt="" />
                      <img src={ententia} alt="" />
                      <img src={hapido} alt="" />
                      <img src={healthi} alt="" />
                      <img src={itspark} alt="" />
                      <img src={namah} alt="" />
                      <img src={supersourcing} alt="" />
                      <img src={takespeks} alt="" />
                      <img src={trustin} alt="" />
                      <img src={uex} alt="" />
                    </div>
                  </Marquee>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Contact;
