import React, { useEffect, useRef, useState } from "react";
import AOS from "aos";
import "./Client.css";
import Leftarrow from "../../assets/LeftArrow.png";
import Rightarrow from "../../assets/RightArrow.png";
import client3 from "../../assets/Vikas.jpg";
import client1 from "../../assets/aninda.jpeg";
import client2 from "../../assets/nilesh.jpeg";
import leftimg from "../../assets/doubleQuotes1.png";
import rightimg from "../../assets/doubleQuotes2.png";

const Client = () => {
  const slider = useRef();
  const [tx, setTx] = useState(0); // State for the translation

  useEffect(() => {
    AOS.init({ duration: 1000 });

    const id = setInterval(() => {
      slideforward();
    }, 10000); // Auto-slide every 10 seconds

    return () => clearInterval(id);
  }, []);

  const slidebackward = () => {
    setTx((prevTx) => Math.min(prevTx + 33.33, 0)); // Move back one slide
  };

  const slideforward = () => {
    setTx((prevTx) => (prevTx > -66.66 ? prevTx - 33.33 : 0)); // Move forward one slide or reset
  };

  useEffect(() => {
    slider.current.style.transform = `translateX(${tx}%)`;
  }, [tx]);

  return (
    <div className="container-fluid clients">
      <div className="container">
        <div className="client-heading" data-aos="fade-up">
          <h4>What our client says</h4>
          <h1>
            Clients <span>Feedbacks</span>
          </h1>
          <p>
            Our clients send us a bunch of smiles with our services and we love
            them.
          </p>
        </div>
        <div className="client-body">
          <img
            src={Leftarrow}
            alt="Previous"
            className="back-btn"
            onClick={slidebackward}
          />
          <img
            src={Rightarrow}
            alt="Next"
            className="next-btn"
            onClick={slideforward}
          />
          <div className="slider">
            <ul ref={slider}>
              <li>
                <div className="slide">
                  <div className="user-info">
                    <img src={client1} alt="Client 1" />
                    <div>
                      <h3>Aninda Mukherjee</h3>
                      <span>Founder of iTechGenic Global Pte Limited</span>
                    </div>
                  </div>
                  <img src={leftimg} alt="Left Quote" className="left-img" />
                  <p>
                    Exceptional service and dedication from the team! They truly
                    go above and beyond, delivering innovative solutions and
                    ideas that have significantly enhanced our project. Highly
                    recommend!
                  </p>
                  <img src={rightimg} alt="Right Quote" className="right-img" />
                </div>
              </li>
              <li>
                <div className="slide">
                  <div className="user-info">
                    <img src={client2} alt="Client 2" />
                    <div>
                      <h3>Nilesh Golecha</h3>
                      <span>Founder of Hexatic Tech Private Limited</span>
                    </div>
                  </div>
                  <img src={leftimg} alt="Left Quote" className="left-img" />
                  <p>
                    What a journey it’s been! Thanks to our incredible team and
                    supportive partners, Hexatic has grown from a vision to a
                    thriving reality. Your hard work and trust have been key to
                    our success.
                  </p>
                  <img src={rightimg} alt="Right Quote" className="right-img" />
                </div>
              </li>
              <li>
                <div className="slide">
                  <div className="user-info">
                    <img src={client3} alt="Client 3" />
                    <div>
                      <h3>Vikas More</h3>
                      <span>Founder of Keva Minds Consultancy</span>
                    </div>
                  </div>
                  <img src={leftimg} alt="Left Quote" className="left-img" />
                  <p>
                    The team’s expertise and proactive approach have been
                    invaluable. They consistently provide high-quality solutions
                    and insightful ideas, contributing greatly to our project's
                    success. A fantastic experience all around!
                  </p>
                  <img src={rightimg} alt="Right Quote" className="right-img" />
                </div>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Client;
